import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { _socials } from 'src/_mock';
import { TwitterIcon, FacebookIcon, LinkedinIcon, InstagramIcon } from 'src/assets/icons';

import { Logo } from 'src/components/logo';

// ----------------------------------------------------------------------

const LINKS = [
  {
    // headline: 'Explore',
    children: [
      // { name: 'Jobs', href: '#' },
      { name: 'Terms', href: paths.terms },
      { name: 'Privacy', href: paths.privacy },
      { name: 'Cookies', href: paths.cookies },
      { name: 'About Us', href: paths.about },
      { name: 'Contact Us', href: paths.contact },
    ],
  },
  // {
  //   headline: 'Specialities',
  //   children: [
  //     { name: 'Astrology Readings ', href: '#' },
  //     { name: 'Numerology Psychics ', href: '#' },
  //     { name: 'Tarot Readings ', href: '#' },
  //     { name: 'Dream Analysis ', href: '#' },
  //     { name: 'Love Psychics ', href: '#' },
  //     { name: 'Fortune Telling ', href: '#' },
  //   ],
  // },
  // { headline: 'Contact', children: [{ name: 'support@dummy-email.com', href: '#' }] },
];

// ----------------------------------------------------------------------

export function Footer({ layoutQuery, sx }) {
  const theme = useTheme();

  const gradientAnimationStyles = `
  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

  return (
    <>
      <style>{gradientAnimationStyles}</style>
      <Box
        component="footer"
        sx={{
          position: 'relative',
          background: `linear-gradient(90deg, ${theme?.palette?.primary?.main}, ${theme?.palette?.primary?.darker})`,
          backgroundSize: '200% 200%',
          animation: 'gradient-animation 5s ease infinite',
          ...sx,
        }}
      >
        <Container
          sx={{
            pb: 5,
            pt: 5,
            textAlign: 'center',
            [theme.breakpoints.up(layoutQuery)]: { textAlign: 'unset' },
          }}
        >
          <Logo isSingle={false} sx={{ ml: { xs: 0, sm: 0, md: 0, lg: -1 } }} light />

          <Grid
            container
            sx={{
              mt: 3,
              justifyContent: 'center',
              [theme.breakpoints.up(layoutQuery)]: { justifyContent: 'space-between' },
            }}
          >
            <Grid {...{ xs: 12, [layoutQuery]: 12 }}>
              <Stack
                spacing={5}
                sx={{
                  flexDirection: 'column',
                  [theme.breakpoints.up(layoutQuery)]: { flexDirection: 'row' },
                }}
              >
                {LINKS.map((list) => (
                  <Stack
                    key={list.headline}
                    spacing={1}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: 1,
                      alignItems: 'center',
                      [theme.breakpoints.up(layoutQuery)]: { alignItems: 'flex-start' },
                    }}
                  >
                    <Typography
                      sx={{ fontSize: '1rem', color: theme.palette.common.white }}
                      component="body1"
                      variant="overline"
                    >
                      {list.headline}
                    </Typography>

                    {list.children.map((link) => (
                      <Link
                        sx={{ color: theme.palette.common.white }}
                        key={link.name}
                        component={RouterLink}
                        href={link.href}
                        color="inherit"
                        variant="body1"
                      >
                        {link.name}
                      </Link>
                    ))}
                  </Stack>
                ))}
              </Stack>
            </Grid>
          </Grid>

          <Typography variant="body2" sx={{ mt: 1, color: theme.palette.common.white }}>
            © 2025 WorkParrots. All Rights Reserved.
          </Typography>
        </Container>
      </Box>
    </>
  );
}

// ----------------------------------------------------------------------

export function HomeFooter({ sx }) {
  return (
    <Box
      component="footer"
      sx={{
        py: 5,
        textAlign: 'center',
        position: 'relative',
        bgcolor: 'background.default',
        ...sx,
      }}
    >
      <Container>
        <Logo />
        <Box sx={{ mt: 1, typography: 'caption' }}>
          © 2025 WorkParrots. All Rights Reserved.
          <br /> made by
          <Link href="https://minimals.cc/"> minimals.cc </Link>
        </Box>
      </Container>
    </Box>
  );
}
